import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getWorkOrderOptions(workOrderId, params) {
    return axios.get(adminPath(`/work_orders/${workOrderId}/custom_fields/options?`) + prepareQueryString(params)).then((response) => {
      return {
        customFields: response.data.map((customField) => an('CustomField', customField)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getWorkOrderValues(workOrderId, params) {
    return axios.get(adminPath(`/work_orders/${workOrderId}/custom_field_values?`) + prepareQueryString(params)).then((response) => {
      return {
        customFieldValues: response.data.map((customFieldValue) => an('CustomFieldValue', customFieldValue)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  manageWorkOrderValues(workOrderId, data) {
    return axios
      .post(adminPath(`/work_orders/${workOrderId}/custom_field_values/manage`), {...data })
      .then((response) => {
        return {
          customFieldValues: response.data.map((customField) => an('CustomFieldValue', customField)),
          total: response.data.length,
        };
      });
  },
  getLocationOptions(locationId, params) {
    return axios.get(adminPath(`/locations/${locationId}/custom_fields/options?`) + prepareQueryString(params)).then((response) => {
      return {
        customFields: response.data.map((customField) => an('CustomField', customField)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getLocationValues(locationId, params) {
    return axios.get(adminPath(`/locations/${locationId}/custom_field_values?`) + prepareQueryString(params)).then((response) => {
      return {
        customFieldValues: response.data.map((customFieldValue) => an('CustomFieldValue', customFieldValue)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  manageLocationValues(locationId, data) {
    return axios
      .post(adminPath(`/locations/${locationId}/custom_field_values/manage`), {...data })
      .then((response) => {
        return {
          customFieldValues: response.data.map((customField) => an('CustomFieldValue', customField)),
          total: response.data.length,
        };
      });
  }
};
