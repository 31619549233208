<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$quotesAPI.getQuotes"
    provider-key="quotes"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    @total-rows-changed="(totalRows) => $emit('total-rows-changed', totalRows)"
  >
    <template #cell(number)="data">
      <b-link :to="{ name: 'quote', params: { id: data.item.id } }">
        {{ data.item.number }}
      </b-link>
    </template>
    <template #cell(status)="data">
      <div class="text-nowrap">
        <status-badge
          :status="data.item.status"
          :status-variants="statusVariants"
        />
      </div>
    </template>
    <template #cell(date)="data">
      {{ data.item.date | dateFormat }}
    </template>
    <template #cell(location_id)="data">
      <b-link :to="{ name: 'location', params: { id: data.item.location_id } }">
        {{ data.item.location.store_number }}
        <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>

    <template #cell(estimated_taxes)="data">
      <money-format
        :value="data.item.estimated_taxes.amount"
        :currency-code="data.item.estimated_taxes.currency"
        subunits-value
      />
    </template>
    <template #cell(price)="data">
      <money-format
        :value="data.item.price.amount + data.item.estimated_taxes.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <b-button-group>
        <b-button
          size="sm"
          variant="link"
          v-b-tooltip.hover.top.window
          title="Convert the Quote to Charges"
          @click="converQuoteToCharges(data.item.id)"
        >
          <i class="fa fa-magic" />
        </b-button>
      </b-button-group>
    </template>
  </sfs-base-table>
</template>

<script>
import StatusBadge from '@/components/shared/StatusBadge.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { QUOTE_TABLE_DEFAULT_COLUMNS, QUOTE_STATUS_VARIANTS, QUOTE_MODEL_NAME } from '@/constants/quotes';
export default {
  name: 'QuotesTable',
  components: {
    StatusBadge,
  },
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => QUOTE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      statusVariants: QUOTE_STATUS_VARIANTS,
      modelName: QUOTE_MODEL_NAME,
    };
  },
  methods: {
    converQuoteToCharges(id) {
      this.$swal({
        text: 'Are you sure you want to convert the Quote to Charges for this Work Order?',
        preConfirm: () => this.$quotesAPI.convertToCharges(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
