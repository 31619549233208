<template>
  <sfs-form-modal
    :id="modalId"
    title="Assign Work Order"
    @on-hide="resetForm"
  >
    <b-form @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.assigned_to_id"
            label-for="work-order-assign-assigned_to_id"
          >
            <user-select
              id="work-order-assign-assigned_to_id"
              :select-class="{ 'is-invalid': $v.form.assigned_to_id.$dirty && $v.form.assigned_to_id.$error }"
              select-label="name"
              :value="form.assigned_to"
              :filter-params="technicianSelectParams"
              @input="onAssignedToChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.assigned_to_id.$dirty"
              id="work-order-assign-assigned_to_id-feedback"
            >
              <span v-if="!$v.form.assigned_to_id.required">Please enter an assigned technician.</span>
              <span v-if="!$v.form.assigned_to_id.serverFailed">{{ serverErrors.assigned_to }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="fieldLabels.done_by_id"
            label-for="work-order-assign-done_by_id"
          >
            <user-select
              id="work-order-assign-done_by_id"
              :select-class="{ 'is-invalid': $v.form.done_by_id.$dirty && $v.form.done_by_id.$error }"
              select-label="name"
              :disabled="!form.assigned_to_id"
              :manager-id="form.assigned_to_id"
              :primary-option="form.assigned_to"
              :value="form.done_by"
              @input="onDoneByChange"
            />
            <b-form-invalid-feedback
              v-if="$v.form.done_by_id.$dirty"
              id="work-order-assign-done_by_id-feedback"
            >
              <span v-if="!$v.form.done_by_id.required">Please enter the technician the work is completed by.</span>
              <span v-if="!$v.form.done_by_id.serverFailed">{{ serverErrors.done_by }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          v-if="numberOfCharges === 1"
        >
          <b-form-group
            :label="fieldLabels.cost"
            label-for="work-order-cost"
          >
            <b-input-group
              prepend="$"
            >
              <b-form-input
                id="work-order-cost"
                v-model="$v.form.cost.$model"
                placeholder="Optional"
                type="text"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label-for="work-order-assign-email_summary">
            <b-form-checkbox
              id="work-order-assign-email_summary"
              v-model="$v.form.email_summary.$model"
            >
              {{ fieldLabels.email_summary }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="work-order-assign-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="work-order-assign-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import { WORK_ORDER_FORM_LABELS } from '@/constants/workOrders';
import { USER_TECHNICIAN_CATEGORY } from '@/constants/users';
import UserSelect from '@/components/admin/users/Select.vue';

export default {
  name: 'WorkOrdersAssignModal',
  components: {
    UserSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
    email_summary: { type: Boolean, default: true },
    numberOfCharges: { type: Number, default: 0 },
  },
  data() {
    return {
      form: {
        assigned_to: this.value.assigned_to,
        assigned_to_id: this.value.assigned_to_id,
        done_by: this.value.done_by,
        done_by_id: this.value.done_by_id,
        cost: null,
        cost_cents: null,
        email_summary: this.email_summary,
      },
      fieldLabels: {
        ...WORK_ORDER_FORM_LABELS,
        cost: this.$store.getters.translate('work_orders.cost', 'Cost'),
        cost_cents: this.$store.getters.translate('work_orders.cost', 'Cost'),
        email_summary: 'Email Assignment Summary' },
      technicianSelectParams: { category: USER_TECHNICIAN_CATEGORY, active: true },
      modalId: 'work-order-assign-modal',
    };
  },
  validations: {
    form: {
      assigned_to_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('assigned_to');
        },
      },
      done_by_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('done_by');
        },
      },
      cost: {},
      email_summary: {},
    },
  },
  watch: {
    'form.cost': function (newCost) {
      if (newCost) {
        this.form.cost_cents = isNaN(newCost) ? null : Math.round(newCost * 100);
      } else {
        this.form.cost_cents = null;
      }
    },
  },
  methods: {
    onAssignedToChange(user) {
      this.form.assigned_to_id = user ? user.id : null;
      this.form.assigned_to = user;
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
    onDoneByChange(user) {
      this.form.done_by_id = user ? user.id : null;
      this.form.done_by = user;
    },
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$workOrdersAPI
        .assign(this.value.id, this.form)
        .then((workOrder) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', workOrder);
          this.closeModal();
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
  },
};
</script>
