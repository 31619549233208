import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getLocations(params) {
    return axios.get(adminPath('/locations?') + prepareQueryString(params)).then((response) => {
      return {
        locations: response.data.map((location) => an('Location', location)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/locations/${id}`)).then((response) => an('Location', response.data));
  },
  getFloorTypeOptions() {
    return axios.get(adminPath('/locations/floor_type_options'));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/locations/${id}`), {
        ...data,
      })
      .then((response) => an('Location', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/locations'), {
        ...data,
      })
      .then((response) => an('Location', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/locations.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
};
