<template>
  <sfs-form-modal
    :id="modalId"
    title="Update Settings"
    size="lg"
    @on-hide="resetForm"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <h3>General</h3>
      <b-row class="mb-3">
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group label-for="client-settings-invoice_work_orders_individually">
            <b-form-checkbox
              switch
              id="client-invoice_work_orders_individually"
              v-model="$v.form.invoice_work_orders_individually.$model"
            >
              {{ fieldLabels.invoice_work_orders_individually }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group label-for="client-settings-work_order_charges_require_service">
            <b-form-checkbox
              switch
              id="client-settings-work_order_charges_require_service"
              v-model="$v.form.work_order_charges_require_service.$model"
            >
              {{ fieldLabels.work_order_charges_require_service }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label-for="client-settings-notify_on_work_order_create">
            <b-form-checkbox
              switch
              id="client-settings-notify_on_work_order_create"
              v-model="$v.form.notify_on_work_order_create.$model"
            >
              {{ fieldLabels.notify_on_work_order_create }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <h3 class="mb-3">
        No Show Fees
      </h3>
      <b-row>
        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.no_show_service_id"
            label-for="client-settings-no_show_service_id"
          >
            <service-select
              name="client-settings-no_show_service_id"
              :value="form.no_show_service"
              @input="onNoShowServiceChange"
              :client-id="value.id"
              client-unassigned
              :select-class="{ 'is-invalid': $v.form.no_show_service_id.$dirty && $v.form.no_show_service_id.$error }"
            />
            <b-form-invalid-feedback
              v-if="$v.form.no_show_service_id.$dirty"
              class="client-settings-no_show_service_id-feedback"
            >
              <span v-if="!$v.form.no_show_service_id.required">Please enter a Service.</span>
              <span v-if="!$v.form.no_show_service_id.serverFailed">{{ serverErrors.no_show_service }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.no_show_number_format"
            label-for="client-settings-charge-no_show_number_format"
          >
            <b-form-input
              name="client-settings-charge-no_show_number_format"
              v-model="$v.form.no_show_number_format.$model"
              :state="$v.form.no_show_number_format.$dirty ? !$v.form.no_show_number_format.$error : null"
              type="text"
            />
            <b-form-invalid-feedback
              v-if="$v.form.no_show_number_format.$dirty"
              class="client-settings-charge-no_show_number_format-feedback"
            >
              <span v-if="!$v.form.no_show_number_format.maxLength">
                The number format must be less than {{ $v.form.no_show_number_format.$params.maxLength.max + 1 }} characters.
              </span>
              <span v-if="!$v.form.no_show_number_format.serverFailed">{{ serverErrors.no_show_number_format }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.no_show_cost"
            label-for="client-settings-no_show_cost"
          >
            <b-input-group
              prepend="$"
              :class="{ 'is-invalid': $v.form.no_show_cost.$dirty && $v.form.no_show_cost.$error, 'has-warning': this.negativeProfitability }"
            >
              <b-form-input
                name="client-settings-no_show_cost"
                v-model="$v.form.no_show_cost.$model"
                :state="$v.form.no_show_cost.$dirty ? !$v.form.no_show_cost.$error : null"
                type="text"
              />
            </b-input-group>
            <b-form-invalid-feedback
              v-if="$v.form.no_show_cost.$dirty"
              :state="$v.form.no_show_cost.$dirty ? !$v.form.no_show_cost.$error : null"
              class="client-settings-no_show_cost-feedback"
            >
              <span v-if="!$v.form.no_show_cost.required">Please enter a cost.</span>
              <span v-if="!$v.form.no_show_cost.decimal">Please enter a number.</span>
              <span v-if="!$v.form.no_show_cost.serverFailed">
                {{ serverErrors.no_show_cost_cents }}
              </span>
            </b-form-invalid-feedback>
            <div class="warning-feedback">
              <span v-if="negativeProfitability">Warning: cost is more than the price.</span>
            </div>
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            :label="fieldLabels.no_show_price"
            label-for="client-settings-no_show_price"
          >
            <b-input-group
              prepend="$"
              :class="{ 'is-invalid': $v.form.no_show_price.$dirty && $v.form.no_show_price.$error }"
            >
              <b-form-input
                name="client-settings-no_show_price"
                v-model="$v.form.no_show_price.$model"
                :state="$v.form.no_show_price.$dirty ? !$v.form.no_show_price.$error : null"
                type="text"
              />
            </b-input-group>
            <b-form-invalid-feedback
              v-if="$v.form.no_show_price.$dirty"
              :state="$v.form.no_show_price.$dirty ? !$v.form.no_show_price.$error : null"
              class="client-settings-no_show_price-feedback"
            >
              <span v-if="!$v.form.no_show_price.required">Please enter a price.</span>
              <span v-if="!$v.form.no_show_price.decimal">Please enter a number.</span>
              <span v-if="!$v.form.no_show_price.serverFailed">
                {{ serverErrors.no_show_price_cents }}
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label-for="client-settings-complete_work_orders">
            <b-form-checkbox
              switch
              id="client-settings-complete_work_orders"
              v-model="$v.form.complete_work_orders.$model"
            >
              {{ fieldLabels.complete_work_orders }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            id="client-settings-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            id="client-settings-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { decimal, requiredIf, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';
import ServiceSelect from '@/components/admin/services/Select.vue';

export default {
  name: 'ClientsSettingsModal',
  components: {
    ServiceSelect,
  },
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      modalId: 'client-settings-modal',
      form: {
        work_order_charges_require_service: this.value.work_order_charges_require_service,
        notify_on_work_order_create: this.value.notify_on_work_order_create,
        invoice_work_orders_individually: this.value.invoice_work_orders_individually,
        no_show_service_id: this.value.no_show_service_id,
        no_show_service: this.value.no_show_service,
        no_show_number_format: this.value.no_show_number_format,
        no_show_cost: this.value.no_show_cost && this.value.no_show_cost.amount ? this.value.no_show_cost.amount / 100 : 0,
        no_show_cost_cents: this.value.no_show_cost && this.value.no_show_cost.amount ? this.value.no_show_cost.amount : 0,
        no_show_price: this.value.no_show_price && this.value.no_show_price.amount ? this.value.no_show_price.amount / 100 : 0,
        no_show_price_cents: this.value.no_show_price && this.value.no_show_price.amount ? this.value.no_show_price.amount : 0,
        complete_work_orders: this.value.complete_work_orders,
      },
      fieldLabels: {
        work_order_charges_require_service: 'All Work Order Charges require a service',
        notify_on_work_order_create: 'Send notifications to managers when a Work Order is created',
        invoice_work_orders_individually: 'Invoice Work Orders individually',
        no_show_service_id: 'Service',
        no_show_service: 'Service',
        no_show_number_format: 'Number Format',
        no_show_cost: this.$store.getters.translate('work_orders.cost', 'Cost'),
        no_show_price: this.$store.getters.translate('work_orders.price', 'Price'),
        complete_work_orders: 'Original Work Order should be completed'
      },
    };
  },
  validations: {
    form: {
      work_order_charges_require_service: {},
      invoice_work_orders_individually: {},
      notify_on_work_order_create: {},
      no_show_service_id: {
        required: requiredIf(function () {
          return (this.form.no_show_cost_cents > 0 || this.form.no_show_price_cents > 0) && this.value.work_order_charges_require_service;
        }),
        serverFailed() {
          return !this.hasServerErrors('no_show_service');
        },
      },
      no_show_number_format: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('no_show_number_format');
        },
      },
      no_show_cost: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('no_show_cost_cents');
        },
      },
      no_show_price: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('no_show_price_cents');
        },
      },
      complete_work_orders: {},
    },
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.$clientsAPI
        .update(this.value.id, this.form)
        .then((client) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.closeModal();
          this.$emit('input', client);
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
    onNoShowServiceChange(service) {
      this.form.no_show_service_id = service ? service.id : null;
      this.form.no_show_service = service;
    },
  },
  computed: {
    negativeProfitability() {
      return this.form.no_show_cost_cents > this.form.no_show_price_cents;
    },
  },
  watch: {
    'form.no_show_cost': function (newCost) {
      this.form.no_show_cost_cents = isNaN(newCost) ? 0 : Math.round(newCost * 100);
    },
    'form.no_show_price': function (newPrice) {
      this.form.no_show_price_cents = isNaN(newPrice) ? 0 : Math.round(newPrice * 100);
    },
  },
};
</script>
