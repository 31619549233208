<template>
  <b-row>
    <b-col>
      <div
        v-if="timelineTrail"
        class="note-transition"
      />
      <div :class="{ 'bg-body': !isPinned, 'bg-body-dark': isPinned }">
        <b-row>
          <b-col
            cols="9"
            class="mr-auto font-w600"
          >
            <i
              v-if="isPinned"
              class="text-primary mr-2 fa fa-thumbtack"
            />

            {{ value.user.name }}
            <b-badge
              v-if="isTechnician"
              variant="secondary"
              class="font-size-sm ml-2"
            >
              {{ value.user.category | humanizedFormat | capitalizeFormat }}
            </b-badge>

            <b-badge
              v-if="isExternal"
              variant="secondary"
              class="font-size-sm ml-2"
              v-b-tooltip.hover
              :title="`Can be shared with the ${$store.getters.translate('client', 'Client')}`"
            >
              External
            </b-badge>
          </b-col>
          <b-col cols="auto">
            <div class="d-flex align-items-center">
              <div
                v-b-tooltip.hover
                :title="value.created_at | dateTimeFormat"
              >
                {{ value.created_at | dateOrTimeFormat }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-0 mt-1">
          <b-col
            cols="11"
            class="mr-auto text-prewrap"
          >
            <timeline-item-reference
              v-if="showReference"
              :reference-type="value.reference_type"
              :reference="value.reference"
            />
            <div>{{ value.content }}</div>
          </b-col>
          <b-col cols="auto">
            <b-dropdown
              variant="link"
              size="sm"
              no-caret
              right
            >
              <template #button-content>
                <i class="fa fa-ellipsis-v" />
              </template>
              <b-dropdown-item
                variant="link"
                @click="() => isPinned ? unpinNote() : pinNote()"
              >
                {{ isPinned ? 'Unpin Note' : 'Pin Note' }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="() => destroyNote()"
              >
                Delete Note
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { isNil as _isNil } from 'lodash';
import { DateTime } from 'luxon';
import TimelineItemReference from './TimelineItemReference.vue';
import { USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY } from '@/constants/users';

export default {
  components: { TimelineItemReference },
  name: 'NotesTimelineItem',
  props: {
    value: {
      type: Object,
      required: true,
    },
    showReference: {
      type: Boolean,
      default: false,
    },
    timelineTrail: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    destroyNote() {
      this.$swal({
        text: 'Once it\'s deleted, its gone forever!',
        preConfirm: () => this.$notesAPI.updateNote(this.value.id, { deleted_at: DateTime.now().toISODate() }),
      }).then((result) => {
        if (result.value) {
          this.$emit('on-destroy');
        }
        result.timer;
      });
    },
    pinNote() {
      this.$swal({
        text: 'Are you sure you want to pin this note?',
        preConfirm: () => this.$notesAPI.updateNote(this.value.id, { pinned_at: DateTime.now().toISO() }),
      }).then((result) => {
        if (result.value) {
          this.$emit('on-pinned', result.value);
        }
        result.timer;
      });
    },
    unpinNote() {
      this.$swal({
        text: 'Are you sure you want to unpin this note?',
        preConfirm: () => this.$notesAPI.updateNote(this.value.id, { pinned_at: null }),
      }).then((result) => {
        if (result.value) {
          this.$emit('on-unpinned', result.value);
        }
        result.timer;
      });
    },
  },
  computed: {
    isPinned() {
      return !_isNil(this.value.pinned_at);
    },
    isExternal() {
      return !this.value.internal;
    },
    isTechnician() {
      return [USER_TECHNICIAN_CATEGORY, USER_SUB_TECHNICIAN_CATEGORY].includes(this.value.user.category);
    },
  },
};
</script>
<style scoped>
.note-transition {
  height: 2.5rem;
  margin-left: 1rem;
  border-left: 2px solid #DEE2E6;
}
</style>
